import { AxiosError } from 'axios';
import { useMutation } from 'react-query';

import { logging, LoggingReqBody } from 'api';
import { Error } from 'api/config';

function useLogging() {
  return useMutation<number, AxiosError<Error>, LoggingReqBody>(
    (payload) => logging(payload),
    {},
  );
}

export default useLogging;
