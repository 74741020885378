import React, { useEffect, useRef, useState } from 'react';
import PopupAD from './PopupAD';
import { useAtom, useAtomValue } from 'jotai';
import { kioskStatusAtom, loginInfoAtom } from 'store/globalStateAtom';
import { postLogNCrash } from 'api/nhn';

type BannerType = {
  footerUrl: string;
  popUpUrl: string;
  advertisementDetailId: number;
};

function BannerAD() {
  const kioskStatus = useAtomValue(kioskStatusAtom);
  const bannerList = (kioskStatus?.adsUrls?.mainUrl as BannerType[]) ?? [];
  const [isOpen, setIsOpen] = useState(false);
  const [isRemoving, setIsRemoving] = useState(false);
  const [currentBannerIndex, setCurrentBannerIndex] = useState(0);
  const timeoutRef = useRef<number | null>(null);
  const isOpenRef = useRef(isOpen);
  const [loginInfo] = useAtom(loginInfoAtom);

  const calculateNumber = () => {
    const currentHour = new Date().getHours();
    return currentHour % bannerList.length;
  };

  const handleButtonClick = (adId: number) => {
    setIsOpen(true);
    postLogNCrash(
      `센터 키오스크 광고 클릭 - 광고id:${adId} name:${loginInfo.name} controlCode:${loginInfo.controlCode}`,
    );
    isOpenRef.current = true;

    timeoutRef.current = window.setTimeout(() => {
      handleClose();
    }, 7000);
  };

  const handleClose = () => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
      timeoutRef.current = null;
    }
    setIsRemoving(true);

    timeoutRef.current = window.setTimeout(() => {
      setIsOpen(false);
      isOpenRef.current = false;
      setIsRemoving(false);
      timeoutRef.current = null;
    }, 500);
  };

  useEffect(() => {
    setCurrentBannerIndex(calculateNumber());

    const interval = setInterval(() => {
      if (!isOpenRef.current) {
        setCurrentBannerIndex(calculateNumber());
      }
    }, 60 * 60 * 1000);

    return () => clearInterval(interval);
  }, []);

  return (
    <>
      {bannerList.map((bannerInfo, index) => {
        if (index === currentBannerIndex) {
          return (
            <div key={bannerInfo.popUpUrl}>
              <button
                className={`fixed bottom-0 left-0 right-0 z-10 bg-transparent border-none cursor-pointer`}
                onClick={() =>
                  handleButtonClick(bannerInfo.advertisementDetailId)
                }
              >
                <img src={bannerInfo?.footerUrl} alt='배너광고' />
              </button>
              {isOpen && (
                <div
                  className={`fixed top-0 bottom-0 left-0 right-0 z-10 w-screen bg-black bg-opacity-50 ${
                    isRemoving ? 'animate-fadeout' : 'animate-fadein'
                  }`}
                >
                  <div
                    className={`w-screen fixed bottom-0 left-0 right-0 top-0 flex justify-center items-end ${
                      isRemoving ? 'animate-slideDown' : 'animate-slideUp'
                    }`}
                    onClick={handleClose}
                  >
                    <PopupAD
                      popUpUrl={bannerInfo?.popUpUrl}
                      adID={bannerInfo.advertisementDetailId}
                    />
                  </div>
                </div>
              )}
            </div>
          );
        }
        return '';
      })}
    </>
  );
}

export default BannerAD;
