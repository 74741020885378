import { AxiosError } from 'axios';
import { useAtom } from 'jotai';
import { Dispatch, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';

import { fetchKioskStatus } from 'api';
import {
  KioskStatus,
  kioskStatusAtom,
  modalInfoAtom,
} from 'store/globalStateAtom';

function useFetchKioskStatus(
  setVideoUrl: Dispatch<SetStateAction<string | null>>,
) {
  const { t } = useTranslation();
  const [, setKioskStatus] = useAtom(kioskStatusAtom);
  const [, setModalInfo] = useAtom(modalInfoAtom);

  //@ts-ignore
  return useMutation<
    KioskStatus & { s3Url: string | null },
    AxiosError,
    string
  >((payload) => fetchKioskStatus(payload), {
    retry: false,
    onSuccess: (data) => {
      const { s3Url, ...kioskStatus } = data;
      setVideoUrl(s3Url);
      setKioskStatus(kioskStatus);
    },
    onError: (err) => {
      if (err.message === 'Network Error') {
        setModalInfo({
          title: t('error_network_title'),
          description: t('error_network_desc'),
          icon: 'ALERT',
        });
      } else {
        setModalInfo({
          title: '에러가 발생했습니다.',
          description: t('error_default_desc'),
          icon: 'ALERT',
        });
      }
    },
  });
}

export default useFetchKioskStatus;
