import React from 'react';
import { useAtom } from 'jotai';

import {
  adminLoginInfoAtom,
  billTypeAtom,
  KioskStatus,
  kioskStatusAtom,
  modalInfoAtom,
} from 'store/globalStateAtom';
import { convertErrorCode, formatBillType } from 'utils';
import errorIcon from 'images/common/error_round.png';
import checkRoundIcon from 'images/common/check_round.png';
import checkIcon from 'images/common/check.png';
import resetIcon from 'images/common/reset_white.png';
import playIcon from 'images/common/play.png';

type Props = {
  resetKiosk: () => void;
};
function ErrorStatus({ resetKiosk }: Props) {
  const [kioskStatus] = useAtom(kioskStatusAtom);
  const [adminLoginInfo] = useAtom(adminLoginInfoAtom);
  const [billType] = useAtom(billTypeAtom);
  const [, setModalInfo] = useAtom(modalInfoAtom);
  const {
    bd1ErrorCode,
    bd2ErrorCode,
    bd3ErrorCode,
    bd1Error,
    bd2Error,
    bd3Error,
    hp1Error,
  } = kioskStatus as KioskStatus;
  const haveAnyErrors = Boolean(bd1Error || bd2Error || hp1Error || bd3Error);
  const { code, message } = convertErrorCode(
    bd1Error ? bd1ErrorCode : bd2Error ? bd2ErrorCode : bd3ErrorCode,
  );

  const openErrorHelpVideo = () => {
    setModalInfo({
      icon: 'RESET',
      description: `영상에 따라 조치 후 상태확인을 통해 키오스크 상태를 확인해주세요.`,
      btnText: '닫기',
      btnClass:
        'bg-mono-10 text-mono-80 active:bg-mono-20 w-[240px] text-white', // 왼쪽 버튼
      btnCallback: () => {},
      subBtnText: '상태확인',
      subBtnClass:
        'bg-blue-100 text-white active:bg-blue-150 flex justify-center items-center gap-2 w-[502px]', // 오른쪽 버튼
      subBtnCallback: () => {
        resetKiosk();
      },
      videoUrl:
        'https://ktp-image.s3.ap-northeast-2.amazonaws.com/ktpKiosk/video/kiosk-error-handling.mp4',
    });
  };

  return (
    <>
      <div className='flex items-center gap-6 mt-12 mb-6 '>
        <h3 className='font-semibold text-head-02 text-mono-80'>
          키오스크 상태
        </h3>
        {haveAnyErrors && (
          <span className='font-semibold text-warning-100 text-head-02'>
            {hp1Error ? '에러 코드 ER10' : code}
          </span>
        )}
      </div>
      <div className='flex items-start gap-3'>
        <img
          src={haveAnyErrors ? errorIcon : checkRoundIcon}
          className='mt-1 w-9 h-9'
          alt='kiosk_status'
        />
        <p className='font-semibold whitespace-pre-line text-body-01 text-mono-80'>
          {haveAnyErrors
            ? hp1Error
              ? '동전 방출기 에러입니다. 동전 방출기를 확인해주세요.'
              : message +
                `\n(${formatBillType(
                  billType[bd1Error ? 'bd1' : bd2Error ? 'bd2' : 'bd3'],
                )} 지폐 방출기 확인)`
            : '키오스크 상태가 정상입니다.'}
        </p>
      </div>
      {Boolean(['ER07'].includes(code) && !adminLoginInfo.manager) ? (
        <></>
      ) : (
        <div className='flex justify-center gap-10'>
          <button
            className='flex items-center gap-2 py-5 mt-20 font-semibold text-white bg-blue-100 rounded-100 px-44 text-body-01 active:bg-blue-150 disabled:bg-blue-20'
            onClick={resetKiosk}
            disabled={!haveAnyErrors}
          >
            <img
              src={haveAnyErrors ? resetIcon : checkIcon}
              className='w-9 h-9'
              alt='reset'
            />
            {haveAnyErrors ? '상태 확인' : '상태 정상'}
          </button>
          {haveAnyErrors && (
            <button
              className='flex items-center gap-2 px-16 py-5 mt-20 font-semibold text-white rounded-100 bg-mono-35 text-body-01 active:bg-gray-500'
              onClick={openErrorHelpVideo}
            >
              <img src={playIcon} className='w-9 h-9' alt='reset' />
              해결 영상
            </button>
          )}
        </div>
      )}
    </>
  );
}

export default ErrorStatus;
