import { MAX_BILL_COUNT, MAX_COIN_COUNT } from 'constants/bill.const';
import { useAtom } from 'jotai';

import { billTypeAtom } from 'store/globalStateAtom';
import { formatBillType } from 'utils';

type Props = {
  type: string;
  isError: boolean;
  count: number;
};
function CashAmount({ type, isError, count }: Props) {
  const [billType] = useAtom(billTypeAtom);

  return (
    <div className='flex flex-col items-center justify-between flex-1 gap-6 p-8 bg-white rounded-20'>
      <h4 className={isError ? 'text-warning-100' : ''}>
        {formatBillType(billType[type])} ({type.toUpperCase()})
      </h4>
      <p>
        {count || 0} / {type === 'hp1' ? MAX_COIN_COUNT : MAX_BILL_COUNT}
      </p>
    </div>
  );
}

export default CashAmount;
