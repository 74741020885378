export const Commands = {
  CMD_CONTROL: 0x10,
  CMD_CHECK: 0x11,
  CMD_EMIT: 0x12,
  RES_STATUS_CODE: 0x13,
  RES_BULK_EMISSION_COUNT: 0x16,
  RES_TOTALINPUT_COUNT: 0x17,
  RES_TOTAL_EMISSION_COUNT: 0x18,
  RES_EMITTER_ERROR_CODE: 0x19,
};

export const Errors = {
  ERR_EMPTY: 0x10,
  ERR_JAM: 0x20,
  ERR_BILL_DOUBLE: 0x30,
  ERR_SHUTTER: 0x40,
  ERR_BILL_LENGTH_LONG: 0x50,
  ERR_BILL_LENGTH_SHORT: 0x60,
  ERR_ORDER: 0x70,
  ERR_SENSOR_SETTING: 0x80,
  ERR_MOTOR_LOCK: 0x90,
  ERR_ENCODER_SENSOR: 0x90,
  ERR_NOT_BILL: 0xa0,
};

export type CommandType = keyof typeof Commands;
export type ErrorType = keyof typeof Errors;
