import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAtom } from 'jotai';

import touchIcon from 'images/Promotion/touch.png';
import logoIcon from 'images/Promotion/logo.png';
import { loginInfoAtom } from 'store/globalStateAtom';
import useFetchKioskStatus from 'hooks/useFetchKioskStatus';
import { getMediaType, MediaType } from 'utils';

function PromotionPage() {
  const navigate = useNavigate();
  const [loginInfo] = useAtom(loginInfoAtom);
  const [mediaSourceUrl, setMediaSourceUrl] = useState<null | string>(null);
  const [mediaType, setMediaType] = useState<MediaType>('unknown');
  const { mutate, isSuccess } = useFetchKioskStatus(setMediaSourceUrl);
  const [refundBtnText, setRefundBtnText] = useState(
    'Touch to get a Tax Refund',
  );

  const getTextRefundBtnText = () => {
    const jpnTextUserList = ['25476003200000'];
    const isJPNText = jpnTextUserList.includes(loginInfo.controlCode);
    if (isJPNText) {
      setRefundBtnText('タッチすると税金還付が受けられます');
      return;
    }
    setRefundBtnText('Touch to get a Tax Refund');
  };

  const onClick = () => {
    navigate('/language-selection');
  };

  useEffect(() => {
    getTextRefundBtnText();
  }, [loginInfo]);

  useEffect(() => {
    if (mediaSourceUrl) {
      const type = getMediaType(mediaSourceUrl);
      setMediaType(type);
    }
  }, [mediaSourceUrl]);

  useEffect(() => {
    mutate(loginInfo.controlCode);
  }, []);

  useEffect(() => {
    const handleTouchMove = (e: any) => {
      e.preventDefault();
    };
    document.addEventListener('touchmove', handleTouchMove, { passive: false });
    return () => document.removeEventListener('touchmove', handleTouchMove);
  }, []);

  return (
    <main className='relative h-[1920px]'>
      <button onClick={onClick} className='w-full h-full active:opacity-80'>
        {isSuccess && mediaSourceUrl && mediaType !== 'unknown' ? (
          mediaType === 'video' ? (
            <video
              preload='metadata'
              autoPlay
              muted
              playsInline
              controls={false}
              loop
              className='object-cover w-full h-full'
            >
              <source src={mediaSourceUrl} type='video/mp4' />
              Your browser does not support the video tag.
            </video>
          ) : (
            <img
              src={mediaSourceUrl}
              alt='Promotion Media'
              className='object-cover w-full h-full'
            />
          )
        ) : (
          <div className='flex items-center justify-center w-full h-full bg-mono-10'>
            <img src={logoIcon} className='w-[552px] h-[236px]' alt='ktp' />
          </div>
        )}
        <div className='whitespace-nowrap py-8 px-12 pr-32 bg-[#0E3B904E] rounded-100 text-head-01 text-white flex items-center gap-4 font-bold absolute bottom-36 left-1/2 transform -translate-x-1/2 backdrop-blur-md box-border'>
          <img src={touchIcon} alt='touch_here' className='w-20 h-20' />
          {refundBtnText}
        </div>
      </button>
    </main>
  );
}

export default PromotionPage;
