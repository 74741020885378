import axios, { AxiosRequestConfig } from 'axios';

const SERVER_URL = 'https://api-logncrash.nhncloudservice.com';

export const axiosRequestConfiguration: AxiosRequestConfig = {
  baseURL: SERVER_URL,
  responseType: 'json',
};

const axiosInstance = axios.create(axiosRequestConfiguration);

export const postLogNCrash = async (body: string) => {
  const newPayload = {
    projectName: 'fwKbJDihj8TS5bVb',
    projectVersion: '1.1.15',
    logVersion: 'v2',
    body,
    logSource: 'deploy',
    logType: 'kiosk-ad',
    category: 'kiosk-ad',
    logLevel: 'INFO',
  };
  const res = await axiosInstance.post('/v2/log', newPayload);
  return res.data;
};
